<template>
  <div v-if="groups" class="col-lg-12" data-toggle="stations">
    <div
      v-for="(group, index) in groups"
      :key="index"
      class="btn-group btn-group-justified"
      role="group"
      aria-label="Radio stations"
    >
      <button
        @click="changeStation(station.name)"
        v-for="station in group"
        :key="station.name"
        type="button"
        :dataStation="station.name"
        :class="[getClass(station), { active: currentStation && station.name == currentStation.name }]"
      >
        {{ station.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import console from '@/mixins/console';

export default {
  name: 'StationSelector',
  mixins: [console],
  created() {
    this.log('StationSelector created.');
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('RadioStations', {
      groups: 'stationGroups',
      stations: 'stationList',
      player: 'player',
      currentStation: 'currentStation',
    }),
  },
  methods: {
    ...mapMutations('RadioStations', ['changeStation']),
    getClass(station) {
      let result = ['btn'];
      let style = 'btn-' + (station.featured === true ? 'danger' : 'default');
      result.push(style);

      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>
