<template>
  <div v-if="this.display" class="alert alert-warning">
    {{ this.loadingText }}
  </div>
</template>

<script>
import console from '@/mixins/console';
export default {
  name: 'LoadingIndicator',
  mixins: [console],
  created() {
    this.log(this.text);
  },
  props: {
    display: {
      type: Boolean,
    },
    text: {
      type: String,
    },
  },
  computed: {
    loadingText() {
      if (!this.text || !this.text.length) {
        return 'Loading...';
      }

      return this.text;
    },
  },
};
</script>

<style lang="scss" scoped></style>
