<template>
  <div class="container">
    <div class="well">
      <h1>
        <router-link to="/">
          <img src="/images/radio_pootjie.png" class="img-responsive center-block" alt="Radio Pootjie logo" width="653" height="268" />
        </router-link>
      </h1>
      <NetworkConnection />
      <p class="text-center">
        <span id="lblNowPlaying" class="brown-1 bold">
          {{ currentStationName }}
        </span>
      </p>
      <div class="form-group">
        <AudioPlayer />
        <StationSelector />
      </div>
      <LoadingIndicator :display="loading" text="Fetching Radio Stations..." />
      <ul class="link-list">
        <li>
          <router-link :to="{ name: 'Home' }" exact>Home</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Information' }">Information</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Disclaimer' }">Disclaimer</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Issues' }">Issues</router-link>
        </li>
      </ul>
      <router-view />
      <p class="text-center">
        Developed by
        <a href="https://www.netsted.co.za" target="_blank" rel="noopener">
          Netsted
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AudioPlayer from '@/components/AudioPlayer';
import StationSelector from '@/components/StationSelector';
import LoadingIndicator from '@/components/LoadingIndicator';
import NetworkConnection from '@/components/NetworkConnection';

export default {
  name: 'HomePage',
  data() {
    return {};
  },
  computed: {
    ...mapState('RadioStations', ['stationList', 'currentStation']),
    loading() {
      return !this.stationList || this.stationList.length == 0;
    },
    currentStationName() {
      if (this.currentStation) {
        return this.currentStation.name;
      }
      return 'Select a station below';
    },
  },
  components: {
    AudioPlayer,
    StationSelector,
    LoadingIndicator,
    NetworkConnection,
  },
  watch: {
    currentStation(newValue) {
      if (!newValue || !newValue.name) {
        return;
      }

      let data = {
        category: 'Station: ' + newValue.name,
        action: 'click',
        label: newValue.name,
        value: 'value',
      };

      window.umami && window.umami.trackEvent(data.category, data.action);
    },
  },
};
</script>
<style lang="scss">
@use '../assets/sass/_main';
</style>
