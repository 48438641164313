<template>
  <div v-if="this.currentStation">
    <div id="playerContainer" class="form-group">
      <audio id="player" controls="controls" preload="none" controlsList="nodownload">
        Your browser does not support the audio player.
      </audio>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Plyr from 'plyr';
import console from '@/mixins/console';

export default {
  name: 'AudioPlayer',
  mixins: [console],
  mounted() {
    this.resetElement();
  },
  updated() {
    this.resetElement();
  },
  computed: {
    ...mapState('RadioStations', ['currentStation']),
    currentStationSource() {
      if (this.currentStation) {
        return this.currentStation.src;
      }

      return null;
    },
    qualitySettings() {
      if (!this.currentStation) {
        return [];
      }

      if (!this.currentStation.sources) {
        return [];
      }

      return this.currentStation.sources;
    },
    qualityList() {
      if (!this.currentStation) {
        return [];
      }

      if (!this.currentStation.sources) {
        return [];
      }

      return this.currentStation.sources.map((source) => {
        return Number(source.size);
      });
    },
  },
  methods: {
    ...mapMutations('RadioStations', ['updatePlayer']),
    getElement() {
      if (typeof Plyr === 'undefined') {
        this.error('Plyr is required.');
        return;
      }

      if (this.$el.nodeType === 8) {
        return;
      }

      if (!this.$el.querySelector('audio')) {
        this.error('No audio element found.');
        return;
      }

      if (!this.currentStation) {
        this.error('No station selected');
        return;
      }

      return this.$el.querySelector('audio');
    },
    resetElement() {
      let audio = this.getElement();
      if (!audio) {
        return;
      }

      if (audio.plyr) {
        audio.plyr.destroy();
      }

      let container = this.$el.querySelector('#playerContainer');
      container.childNodes.forEach((element) => {
        container.removeChild(element);
      });

      audio = document.createElement('audio');
      audio.controls = 'controls';
      audio.preload = 'none';
      audio.controlsList = 'nodownload';

      audio.src = this.currentStation.src;
      audio.querySelectorAll('source').forEach((child) => {
        audio.removeChild(child);
      });

      container.appendChild(audio);

      if (!this.currentStation.sources) {
        this.setupPlyr({
          settings: [],
          source: this.currentStation.src,
          quality: {},
        });
        return;
      }

      this.currentStation.sources.forEach((entry) => {
        let source = document.createElement('source');
        source.src = entry.src;
        source.setAttribute('size', Number(entry.size));
        source.type = 'audio/aac';
        audio.appendChild(source);
      });

      this.setupPlyr({
        settings: ['quality'],
        quality: {
          forced: true,
          options: this.qualityList,
          default: this.qualityList[0],
        },
        source: {
          type: 'audio/aac',
          sources: this.currentStation.sources,
        },
        i18n: {
          qualityBadge: {
            160: 'High',
            80: 'Medium',
            40: 'Low',
          },
        },
      });
    },
    setupPlyr(args) {
      let audio = this.getElement();
      if (!audio) {
        return;
      }

      new Plyr(audio, Object.assign({ storage: { enable: false }, autoPlay: true }, args)).on('loadstart', (e) => {
        let player = e.detail.plyr;
        try {
          player.play();
        } catch (error) {
          this.warn(error);
        }
      });
    },
  },
};
</script>

<style src="plyr/dist/plyr.css"></style>
<style lang="scss">
@use '../assets/sass/_variables' as *;
.plyr--audio {
  .plyr__controls {
    background-color: $brown-1;
    color: #fff;
    padding: 4px 10px;
  }
}
.plyr--full-ui input[type='range'] {
  color: $brown-3;
}
.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded='true'] {
  background: $brown-3;
  box-shadow: 0 0 0 5px rgba(192, 183, 177, 0.5);
}

.plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background: $brown-3;
}

.plyr__controls .plyr__controls__item {
  margin-left: $gutter_size;
}

audio {
  max-width: 100%;
  width: 100%;
}
</style>
