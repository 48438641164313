<template>
  <div v-if="this.display" class="alert alert-warning">{{ this.message }}</div>
</template>

<script>
import console from '@/mixins/console';
export default {
  name: 'NetworkConnection',
  mixins: [console],
  data() {
    return {
      onLine: undefined,
      effectiveType: undefined,
      saveData: undefined,
    };
  },
  mounted() {
    if (typeof navigator.connection === 'undefined') {
      this.warn('navigator.connection is not available.');
      return;
    }

    this.populateData();

    navigator.connection.onchange = this.populateData;
  },
  computed: {
    display() {
      if (!this.message) {
        return false;
      }

      return true;
    },
    message() {
      if (typeof this.onLine === 'undefined') {
        return;
      }

      if (!this.onLine) {
        return 'You appear to be offline. Streaming will not work.';
      }

      var conType = (this.effectiveType || '').toLowerCase();
      var validTypes = ['slow-2g', '2g', '3g', '4g'];

      if (validTypes.indexOf(conType) === -1) {
        return;
      }

      if (conType !== 'slow-2g' && conType !== '2g') {
        return;
      }

      return 'You seem to be on a slow connection. Try streaming on a lower quality.';
    },
  },
  methods: {
    populateData() {
      this.onLine = navigator.onLine;
      this.effectiveType = navigator.connection.effectiveType;
      this.saveData = navigator.connection.saveData;
    },
  },
};
</script>
